
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tooltip-activeusers{
  visibility: hidden;
  position: absolute;
}
.has-tooltip-activeusers:hover .tooltip-activeusers {
  visibility: visible;
  z-index: 100;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mejiascrollbar::-webkit-scrollbar-thumb {
     
      border-radius: 8px;
      border: 4px solid #717171;
      background-clip: content-box;
      background-color: #717171;  
}

.mejiascrollbar {
  scroll-margin-top: 2px;
  scroll-margin-bottom: 2px;
  scroll-margin-right: 3px;
}

.textBox {
  text-align: left;
  word-break: break-word;
  font-size: 1rem;
  line-height: 1.375rem;
}

.maxheightchatimg {
  /*max-height: 300px;*/
  max-width: 500px;
}

/*
DOT FLASHING
*/

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.bubble1 {
  width: 45px;
  height: 45px;
}

.lexend {
 font-family: 'Lexend Deca', sans-serif; 
}
.mono {
  font-family: 'Roboto Mono', monospace;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.wrapboi {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.breakanywhere {
  line-break: anywhere;
}